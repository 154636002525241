
import { mapGetters } from "vuex";

export default {
	props: {
		pageTitle: String,
		salonInfo:{
			type:Boolean,
			default:true
		}
	},
	data() {
		return {
			contactsImages: new Array(5).fill(1).map((x, y) => require(`~/assets/img/slider-dealer-contacts/slide-${x + y}.webp`))
		}
	},
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		})
	},
}
