import { render, staticRenderFns } from "./services-index-swiper.vue?vue&type=template&id=49ff1cea&"
import script from "./services-index-swiper.vue?vue&type=script&lang=js&"
export * from "./services-index-swiper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ServicesItemSmallDesktop: require('/home/d/didisher2/leks-cars.ru/www/components/services/services-item-small-desktop.vue').default})
