
import { mapActions, mapGetters, mapMutations } from "vuex";
import offers from "@/apollo/queries/offer/offers";

export default {
	data() {
		return {
			showButtonCompilation: true
		}
	},
	computed: {
		...mapGetters({
			offers: 'catalog/catalog-cars/offers',
			loading: 'catalog/catalog-cars/loading'
		}),
		offers_list() {
			return this.offers.data
		}
	},
	methods: {
		...mapActions({
			request: 'filters/filters/request',
			openModal: 'modal/modal-main/openModal',
		}),
		...mapMutations({
			setOffers: 'catalog/catalog-cars/SET_OFFERS',
			setLoading: 'catalog/catalog-cars/SET_LOADING'
		}),
		// async getOffers() {
		// 	await this.setLoading(true)
		// 	let response = await this.request({
		// 		query: offers,
		// 		variables: {
		// 			page: 0,
		// 			limit: 10,
		// 			set: this.set,
		// 			dateFormat: 'j F Y года.',
		// 			sort: this.set === 'best' ? 'created_at|desc' : 'price|asc'
		// 		}
		// 	})
		// 	this.setOffers(response.data.offers)
		// 	await this.setLoading(false)
		// },

		async filterRequest(assignVariables) {
			try {
				await this.setLoading(true)
				let response = await this.request({ query: offers, variables: assignVariables })

				await this.setLoading(false)
				this.setOffers(response.data.offers);

			} catch (error) {
				return this.$nuxt.error({ statusCode: 404, message: '404' })
			}
		},

		moreOffers() {
			this.filterRequest({
				url: '/cars',
				page: 1,
				dateFormat: 'j F Y года.',
				// year_from: Number(new Date().getFullYear()-4),
				// year_to: Number(new Date().getFullYear()),
				sort: 'year|desc',
				price_from: 0,
				limit: this.$device.isMobile == true ? 3 : 9
			})
		},
		openMenu() {
			let payload = {
				modal_component: 'modal-filter-mobile',
				modal_data: 'filter-mobile',
				modal_title: 'Фильтр',
				modal_sub_title: ''
			}
			this.openModal(payload);
			this.$router.push('/cars');
		}
	},
	// mounted() {
	// 	this.moreOffers();
	// }
}
