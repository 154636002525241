
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      ready: false,
      markerFill: {
        enabled: true,
        color: "black",
        opacity: 1
        // imageHref
      },
      markerIcon: {
        layout: "default#image", // 'default#imageWithContent' для использования с контентом
        imageHref: require("~/assets/icons/mapMarker1.svg"), // адрес изображения или data:image/svg+xml;base64
        imageSize: [43, 55], // размер иконки в px
        /* Следующие поля актуальны для layout: 'default#imageWithContent' */
        content: "some content here", // содержимое контента
        contentLayout:
          '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>' // строковый HTML шаблон для контента
      }
    };
  },
  methods: {
    updateOffsets() {
      if (this.$device.isMobile) { // Размер для мобильных экранов (можете изменить на свой порог)
        this.markerIcon.imageOffset = [-22, -150];
        this.markerIcon.contentOffset = [-22, -150];
        console.log('mob');
        
      } else { // Размер для десктопных экранов
        this.markerIcon.imageOffset = [-22, -55];
        this.markerIcon.contentOffset = [-22, -55];
      }
    },
    ...mapActions({
      // liked: 'favorite/favorite/liked',
      openModal: "modal/modal-main/openModal"
    }),
    async callback() {
      let payload = {
        modal_component: "modal-callback-main"
      };
      await this.openModal(payload);
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
      isNight: "settings/settings/isNight"
    }),
    mapstyle() {
      return !this.$device.isMobile
        ? { width: "150vw", height: "320px" }
        : { width: "100vw", height: "130vw", filter: "grayscale(100%)" };
    },
    timeForCallback() {
      return new Date().getHours() > 21;
    }
  },
  mounted() {
    this.updateOffsets();
    window.addEventListener('resize', this.updateOffsets);

    if (!this.$device.isMobile) {
      const leftpadding = document
        .getElementsByClassName("desc head2 bold red--text line")[0]
        .getBoundingClientRect().left;
      this.$refs.map__leftSide.animate(
        {
          paddingLeft: `${leftpadding}px`,
          opacity: 1
        },
        {
          duration: 1000,
          fill: "forwards",
          easing: "ease-in-out"
        }
      );
    } else {
      this.$refs.map__leftSide.animate(
        {
          opacity: 1
        },
        {
          duration: 1000,
          fill: "forwards",
          easing: "ease-in-out"
        }
      );
    }

    setTimeout(() => {
      this.ready = true;
    }, 500);
  }
};
