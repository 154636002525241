
import { mapActions, mapGetters, mapMutations } from "vuex";
import banks from "@/apollo/queries/bank/banks";

export default {
  props: {
    pageTitle: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      banks: "banks/banks",
      tinkoff: "banks/tinkoff",
      sberbank: "banks/sberbank",
      raiffeisen: "banks/raiffeisen",
      alfa: "banks/alfa",
      sovkombank: "banks/sovkombank",
      vtb: "banks/vtb"
    }),
    banksValue() {
      let value = [...this.banks];

      if (this.$route.path != "/banks") {
        return value.splice(0, 6);
      } else {
        return value;
      }
    }
  },
  data() {
    return {
      f_banks: [
        {
          name: "sberbank",
          rating: 91.8,
          rate: "13"
        },
        {
          name: "rosselhozbank",
          rating: 88.5,
          rate: "8.33"
        },
        {
          name: "tinkoff-bank",
          rating: 88.1,
          rate: "11.99"
        },
        {
          name: "alfa-bank",
          rating: 87.9,
          rate: "11.99"
        },
        {
          name: "vtb",
          rating: 78.4,
          rate: "9.9"
        },
        {
          name: "gazprombank",
          rating: 76.3,
          rate: "12.75"
        },
        {
          name: "bank-soyuz",
          rating: 75.3,
          rate: "11.7"
        },
        {
          name: "raiffeisen-bank",
          rating: 72.6,
          rate: "10.99"
        },
        {
          name: "otkrytie-bank",
          rating: 70.8,
          rate: "11.9"
        },
        {
          name: "bank-ekspobank",
          rating: 69.5,
          rate: "7.9"
        },
        {
          name: "bank-absolut",
          rating: 66.3,
          rate: "21.5"
        },
        {
          name: "bank-mkb",
          rating: 64.1,
          rate: "14.5"
        },
        {
          name: "bank-avangard",
          rating: 61.2,
          rate: "11"
        },
        {
          name: "bank-otp",
          rating: 43.5,
          rate: "13.99"
        },
        {
          name: "rosselhozbank",
          rating: 32.0,
          rate: "15"
        },
        {
          name: "pochta-bank",
          rating: 31.5,
          rate: "16.9"
        },
        {
          name: "centr-invest",
          rating: 24.3,
          rate: "9.5"
        },
        {
          name: "bank-renessanse",
          rating: 23.1,
          rate: "9.9"
        },
        {
          name: "loko-bank",
          rating: 19.3,
          rate: "12.4"
        },
        {
          name: "sovkombank",
          rating: 18.2,
          rate: "14.9"
        },
        {
          name: "europa-bank",
          rating: 17.5,
          rate: "11.99"
        },
        {
          name: "uralsib-bank",
          rating: 13.8,
          rate: "10.9"
        },
        {
          name: "bank-houm-kredit",
          rating: 11.1,
          rate: "11.3"
        },
        {
          name: "europa-bank",
          rating: 10.8,
          rate: "19.9"
        }
      ]
    };
  },
  // mounted() {
  //   if (this.$route.path != '/banks') {
  // 		this.banksValue = this.banksValue.splice(0, 6)
  // 	}
  // }
  // methods: {
  // 	...mapMutations({
  // 		setBanks: 'banks/SET_BANKS'
  // 	}),
  // 	...mapActions({
  // 		request: 'filters/filters/request',
  // 	}),
  // },
  // async fetch() {
  // 	let response = await this.request({ query: banks, variables: {} })
  // 	if (this.$route.path != '/banks') {
  // 		response.data.banks = response.data.banks.splice(0, 6)
  // 	}
  // 	await this.setBanks(response.data.banks)
  // }
};
