

export default {
	props: {
		banks: {
			type: Array,
			default: []
		},
	},
	computed: {
		mainBanks() {
			return this.banks.filter(x => x.name === "Сбербанк" | x.name === "Альфа-Банк" | x.name === "Тинькофф Банк")
		},
		otherBanks() {
			return this.banks.filter(x => x.name !== "Сбербанк" && x.name !== "Альфа-Банк" && x.name !== "Тинькофф Банк")
		}
	}
}
